<template>

  <!-- TODO-ralf events class hab ich behalten wegen base style -->
    <div class="appView events newsletters purpleView">
      <Details v-if="$route.params.id"/>
      <List />
    </div>


</template>
<script>
import List from "./List.vue";
import Details from "@/routing/core/Newsletters/Details.vue";
export default {
  name: 'Newsletters',
  components: {
    Details,
    List
  },

}
</script>
