<template>
  <div class="events-list newsletters-list">
    <div class="mainscroller"
                       :class="(newsletterActive && Object.keys(newsletterActive).length > 0) ? 'active' : ''">
      <Sort @sort="resortOrder($event)" />
      <template v-if="$store.state.lists.newsletters.loading">
        <preLoader height="70vh" style="background: transparent;position: relative;" class="inner" :loading="$store.state.lists.newsletters.loading" />
      </template>
      <template v-else>
      <p class="text-sm text-secondary" v-if="!newsletterList.data.length">
        Es wurden keine Veranstaltungen gefunden.</p>
      <div class="eventWrap" v-for="newsletter in newsletterList.data"
           :class="(newsletterActive && newsletterActive.id === newsletter.id) ? 'activeEvent' : 'notActive'"
           :key="newsletter.id">
        <Headline :newsletter="newsletter"/>
      </div>
      </template>
    </div>
    <pagination list="newsletters" listName="newsletterList" filterName="newslettersFilter"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Newsletters/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Newsletters/Layouts/Sort.vue";
import $ from "jquery";
import preLoader from "@/components/Loading.vue";

export default {
  name: "NewslettersList",
  computed: {
    ...mapGetters(["newsletterList", "newsletterActive"]),
  },
  components: {
    preLoader,
    Sort,
    Headline,
    Pagination
  },

  methods: {

    getEvents(){
      this.$store.dispatch("newslettersFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.newsletters.ordering.value = payload.type;
        this.$store.state.filters.newsletters.direction.value = payload.direction;
      } else {
        this.$store.state.filters.newsletters.ordering.value = payload.type
      }
      this.getEvents();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },

  created() {
    if(this.$store.state.filters.newsletters.ordering.value === "") {
      this.$store.state.filters.newsletters.ordering.value = "id";
      this.$store.state.filters.newsletters.direction.value = "desc";
    }
    this.$store.dispatch("setActiveNewsletter", null);
    this.$store.dispatch("newslettersFilter");
  }
};
</script>
