<template>
  <div class="newsletterLineWrap eventLineWrap" :class="(newsletterActive && newsletterActive.id === newsletter.id) ? 'active' : ''">
    <div class="d-flex">
    <div class="lineitem first l-checkbox">
      <div class="checkbox" v-if="newsletterList">

        <input type="checkbox" :id="'id_'+newsletter.id" :value="newsletter.id" v-if="newsletterList.selected"
               v-model="newsletterList.selected[newsletter.id]"/><label
        :for="'id_'+newsletter.id">
       </label></div>
    </div>
    <div class="lineitem l-title" @click="setNewsletter(newsletter)">
      {{ newsletter.title }}
    </div>
      <div class="lineitem l-iconlist">
        <template v-if="newsletter.state === '1' || newsletter.state === 1">
        <span  tooltip="Aktiv" position="left">
        <i class="fa-duotone fa-circle-check"></i></span>
        </template>
        <template v-if="newsletter.state === '0' || newsletter.state === 0">
        <span tooltip="Inaktiv" position="left">
        <i class="fa-duotone fa-circle-xmark"></i></span>
        </template>
        <template v-if="newsletter.state === '-2' || newsletter.state === -2">
        <span  tooltip="Papierkorb" position="left">
        <i class="fa-duotone fa-trash"></i></span>
        </template>
      </div>
      <div class="lineitem l-iconlist">

        <span :tooltip="$t('newsletters.automatic.' + newsletter.automatic)" position="left">
          <template v-if="newsletter.automatic === '1' || newsletter.automatic === 1">
            <i class="fa-duotone fa-circle-check"></i>
          </template>
          <template v-else>
            <i class="fa-duotone fa-circle-xmark"></i>
          </template>
        </span>

      </div>


    <div class="lineitem l-iconlist">

        <span :tooltip="$t('newsletters.public.' + newsletter.public)" position="left">
          <template v-if="newsletter.public === '1' || newsletter.public === 1">
            <i class="fa-duotone fa-circle-check"></i>
          </template>
          <template v-else>
            <i class="fa-duotone fa-circle-xmark"></i>
          </template>
        </span>

    </div>
      <div class="lineitem l-iconlist">

        <span :tooltip="$t('newsletters.doubleOptIn.' + newsletter.doubleOptIn)" position="left">
          <template v-if="newsletter.doubleOptIn === 'no'">
            <i class="fa-duotone fa-circle-xmark"></i>
          </template>
          <template v-if="newsletter.doubleOptIn === 'yes'">
            <i class="fa-duotone fa-circle-check"></i>
          </template>
        </span>

      </div>
    <div class="lineitem l-iconlist">

      <span :tooltip="'Angemeldet: '+newsletter.sumAccepted" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-check"></i><span><span class="incNumber">{{ newsletter.sumAccepted }}</span></span>
      </span>
    </div>
      <div class="lineitem l-iconlist">
      <span :tooltip="'Double-Opt-In: '+newsletter.sumRequest" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-clock"></i><span><span class="incNumber">{{ newsletter.sumRequest }}</span></span>
      </span>
      </div>
      <div class="lineitem l-iconlist">
      <span :tooltip="'Storno: '+newsletter.sumDenied" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-xmark"></i><span><span class="incNumber">{{ newsletter.sumDenied }}</span></span>
      </span>
      </div>
      <div class="lineitem l-iconlist">
      <span :tooltip="'Automatik-Mails: '+newsletter.sumAutomatic" position="left" class="iconSpace">
        <i class="fa-duotone fa-envelopes-bulk"></i><span><span class="incNumber">{{ newsletter.sumAutomatic }}</span></span>
      </span>
      </div>
      <div class="lineitem l-date">
        <span class="date"><b>{{ $filters.formatDate(newsletter.crm_created_date) }}</b></span>
      </div>
    </div>


    <div class="lineitem last ml-auto">
      <template v-if=" newsletterActive && newsletterActive.id  === newsletter.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeNewsletter');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setNewsletter(newsletter)"></i></template>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.newsletterLineWrap .lineitem {
  width: 100px !important;
  text-align: center !important;
  display: inline !important;
  &.l-title,&.l-checkbox {
    text-align: left !important;
  }
  &.l-date .date {
    font-size: 14px;
  }
  .fa-circle-check {
    color: green
  }
}
</style>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    newsletter: Object,
  },
  computed: {
    ...mapGetters(["newsletterActive", "getEmployees", "newsletterList"])
  },
  methods: {
    setNewsletter(newsletter, subview) {
      if (subview === undefined) {
        subview = "details";
      }
      // Unset if already set:
      if (this.newsletterActive === newsletter.id) {
        // this.$store.dispatch('setTask', {})
        this.$router.push({name: "Newsletters"});
      } else {
        this.$router.push({name: "NewsletterTabs", params: {id: newsletter.id, tab: subview}})
      }
    },
  }
}
</script>
