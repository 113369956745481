<template>
  <div class="card-header card-header-inline">
    <h6 class="editLine" v-if="eventActive && eventActive.title">{{
        eventActive.title
      }}</h6>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-6">
        <UploadComponent :event-id="eventActive.id" :compact="true" :file-info="fileInfo"/>
        <p><small>Dateien auswählen oder auf das Fenster ziehen.</small></p>
        <label class="mt-3">Name</label>
        <input type="text" v-model="fileInfo.text" class="form-control mb-2"/>
        <label>Verfügbarkeit</label>
        <select class="form-select" v-model="fileInfo.type">
          <option value="files">Verfügbar für alle</option>
          <option value="files_public">Verfügbar für Teilnehmer</option>
          <option value="files_protected">Verfügbar für Teilnehmer nach der Veranstaltung</option>
        </select>
      </div>
      <div class="col-12 col-lg-6">
        <ul class="fileList list-group">
          <template v-if="attachments" v-for="at in attachments">
            <li class="list-group-item"><i class="fa-duotone fa-paperclip me-2" @click="downloadFile(at.key, at.type)"></i>
              <i
                class="fa-duotone fa-eye me-2" @click="previewFile(at.key, at.type)"></i>
              <template v-if="at.text">{{at.text}}</template>
              <template v-else>{{at.name}}</template> | <small :tooltip="$t('eventfiles.'+at.type)" position="top">
                <template v-if="at.type==='files'">
                  <i class="fa-duotone fa-files"></i>
                </template>
                <template v-if="at.type==='files_protected'"><i class="fa-duotone fa-file-user"></i></template>
                <template v-if="at.type==='files_public'"><i class="fa-duotone fa-file-shield"></i></template>
              </small>
<div class="btn btn-outline-secondary btn-sm float-end" @click="deleteFile(at.key, at.type)"><i class="fa-duotone fa-trash-alt " ></i></div>

            </li>
          </template>
        </ul>
      </div>
    </div>

  </div>
  <template v-if="file">
    <Modal v-if="modalsList.previewFile">
      <div class="modal show" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <embed :src="'data:'+file.contentType+';base64,'+file.content">
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "Attachments",
  emits: ["update"],
  props: {
    attachments: ""
  },
  components: {
    UploadComponent
  },
  computed: {
    ...mapGetters(["modalsList", "eventActive"])
  },
  data() {
    return {
      file: '',
      fileInfo: {
        text: '',
        type: 'files'
      }
    };
  },
  created() {
    window.addEventListener("triggerReloadFiles", this.resetFields);
    },
  methods: {
    previewFile(attachmentId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/readFile/" + attachmentId + "/" +type,  {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.$store.commit('openModal', 'previewFile');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetFields() {
      this.fileInfo.text = '';
      this.fileInfo.type = 'files';
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    deleteFile(attachmentId, type){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/deleteFile/" + attachmentId+ "/" +type, {headers: headers})
        .then((response) => {
          this.$emit('update');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadFile(attachmentId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/readFile/" + attachmentId+ "/" +type, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.name);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>
